import type IPlayer from '@etf1-interne/player'
import type { SlotInfo, SpotInfo, SlotType } from '@etf1-interne/player'
import type { IVideosData } from '../types/tms'
import { useEffect, useRef, useCallback, useContext, useMemo } from 'react'
import slugify from 'slugify'
import { TagCommanderContext } from '../contexts/tagcommander'
import {
  AD_END,
  AD_START,
  AD_RESUME,
  AD_PAUSE,
  AD_UPDATE,
  AD_PAUSE_DISPLAY,
  SLOT_START,
  SLOT_END,
  ROLL_END,
  MEDIA_INFO,
  TMS_PLAY,
  TMS_PAUSE,
  TMS_END,
  TMS_ROLL_PLAY,
  TMS_SPOT_PLAY,
  TMS_SPOT_PAUSE,
  TMS_SPOT_RESUME,
  TMS_PLAYALONG_FALSE,
  TMS_PLAYALONG_AUTO,
  TMS_PLAYALONG_MANUAL,
  TMS_SPOT_END,
  TMS_ACTION_END,
} from '../constants/playerEvents'

interface IEvent {
  ad_adType: SlotType
  ad_end: boolean
  ad_spotPosition: number
  ad_spotPositionAdType: number
  ad_spotPositionGlobal: number
  ad_spotsAmount: number
  ad_spotsAmountAdType: number
  ad_spotsAmountGlobal: number
  content_video_action: typeof TMS_PLAY | typeof TMS_PAUSE | typeof TMS_END
  content_video_duration: string
  content_video_playAlong: string
  content_video_videoEmNumber: string
  content_video_videoFormat: string
  content_video_videoName: string
  content_video_videoRefvideoId: string
  content_video_videoType: string
  program_attributedEditor: string
  program_broadcastingChannel: string
  program_programNameMove: string
  program_programIdMove: string
  program_programName: string
  program_linkingChannel: string
}

interface IEventTrackerPlayer {
  player: { current: IPlayer }
  playAlongTms: typeof TMS_PLAYALONG_FALSE | typeof TMS_PLAYALONG_AUTO | typeof TMS_PLAYALONG_MANUAL
  isPlayerInitialized: boolean
  videoId?: string | number
  videosData: IVideosData
  mediaInfoRef: {
    current: {
      content_video_videoName?: string
      program_broadcastingChannel?: string
      content_video_videoType?: string
      content_video_duration?: string
      program_programName?: string
      program_linkingChannel?: string
    }
  }
}

export const AdsPlayerListeners = {
  [AD_END]: 'handleAdEnd',
  [AD_START]: 'handleAdStart',
  [AD_PAUSE]: 'handleAdPause',
  [AD_RESUME]: 'handleAdResume',
  [AD_UPDATE]: 'handleUpdate',
  [AD_PAUSE_DISPLAY]: 'handleAdPauseDisplay',
  [SLOT_START]: 'handleSlotStart',
  [SLOT_END]: 'handleSlotEnd',
  [MEDIA_INFO]: 'handleMediaInfoUpdate',
}

export function useEventTrackPlayer({
  player,
  isPlayerInitialized,
  videoId,
  videosData,
  playAlongTms,
  mediaInfoRef,
}: IEventTrackerPlayer) {
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)
  const event = useRef<IEvent>({
    ad_adType: '',
    ad_end: false,
    ad_spotPosition: 1,
    ad_spotPositionAdType: 1,
    ad_spotPositionGlobal: 0,
    ad_spotsAmount: 1,
    ad_spotsAmountAdType: 1,
    ad_spotsAmountGlobal: 1,
    content_video_action: TMS_PLAY,
    content_video_duration: '0',
    content_video_playAlong: TMS_PLAYALONG_FALSE,
    content_video_videoEmNumber: '',
    content_video_videoFormat: '',
    content_video_videoName: '',
    content_video_videoRefvideoId: videoId ? String(videoId) : '',
    content_video_videoType: '',
    program_attributedEditor: '',
    program_broadcastingChannel: '',
    program_programNameMove: '',
    program_programIdMove: '',
    program_linkingChannel: '',
    program_programName: '',
  })

  const setEvent = (spot) => {
    const type: SlotType = spot?.contentType?.toLowerCase() || spot?.type?.toLowerCase() || null

    const { ad_spotPositionGlobal } = event.current
    event.current = {
      ...event.current,
      ad_adType: type || event.current.ad_adType,
      ad_spotPosition: spot?.spotPosition || event.current.ad_spotPosition,
      ad_spotPositionAdType: spot?.currentAdIndex || event.current.ad_spotPositionAdType,
      ad_spotsAmountAdType:
        spot?.adsCount || spot?.spotsAmount || event.current.ad_spotsAmountAdType,
      ad_spotsAmount: spot?.adsCount || spot?.spotsAmount || event.current.ad_spotsAmount,
      ad_spotsAmountGlobal:
        spot?.spotsAmountGlobal ||
        (spot?.type && spot?.adsCount) ||
        event.current.ad_spotsAmountGlobal,
      ad_spotPositionGlobal:
        (spot?.currentAdIndex && ad_spotPositionGlobal) + 1 || ad_spotPositionGlobal,
      ad_end: spot.ad_end || event.current.ad_end,
      content_video_action: spot.content_video_action || event.current.content_video_action,
    }
  }

  const parseEvent = ({
    ad_spotPosition,
    ad_spotPositionAdType,
    ad_spotsAmountAdType,
    ad_spotsAmount,
    ad_spotsAmountGlobal,
    ad_spotPositionGlobal,
    ad_end,
    ...event
  }: IEvent) => {
    return {
      ...event,
      ad_spotPosition: String(ad_spotPosition),
      ad_spotPositionAdType: String(ad_spotPositionAdType),
      ad_spotsAmountAdType: String(ad_spotsAmountAdType),
      ad_spotsAmount: String(ad_spotsAmount),
      ad_spotsAmountGlobal: String(ad_spotsAmountGlobal),
      ad_spotPositionGlobal: String(ad_spotPositionGlobal),
      ad_end: String(ad_end),
    }
  }

  const listeners = useMemo(
    () => ({
      handleSlotStart: (slot: SlotInfo) => {
        if (isTagCommanderReady && slot?.value) {
          setEvent({ ...slot.value, content_video_action: TMS_PLAY, ad_end: false })
          const {
            ad_adType,
            ad_spotsAmount,
            ad_spotsAmountAdType,
            ad_spotsAmountGlobal,
            content_video_action,
            content_video_duration,
            content_video_playAlong,
            content_video_videoEmNumber,
            content_video_videoFormat,
            content_video_videoName,
            content_video_videoRefvideoId,
            content_video_videoType,
            program_attributedEditor,
            program_broadcastingChannel,
            program_programNameMove,
            program_programIdMove,
            ad_end,
          } = event.current

          // Delaying the tms roll play event because such event sent too fast
          // could cause trouble during analysis process
          // Details : https://e-tf1.atlassian.net/browse/LCI-8126
          hit(
            {
              id: TMS_ROLL_PLAY,
              ad_adType,
              ad_spotsAmount: String(ad_spotsAmount),
              ad_spotsAmountAdType: String(ad_spotsAmountAdType),
              ad_spotsAmountGlobal: String(ad_spotsAmountGlobal),
              content_video_action,
              content_video_duration,
              content_video_playAlong,
              content_video_videoEmNumber,
              content_video_videoFormat,
              content_video_videoName,
              content_video_videoRefvideoId,
              content_video_videoType,
              program_attributedEditor,
              program_broadcastingChannel,
              program_programNameMove,
              program_programIdMove,
              ad_end: String(ad_end),
            },
            { delay: 200 },
          )
        }
      },
      handleSlotEnd: (slot: SlotInfo) => {
        if (isTagCommanderReady && slot?.value) {
          setEvent({ ...slot.value, content_video_action: TMS_END, ad_end: true })

          hit({
            id: ROLL_END,
            ...parseEvent(event.current),
          })
        }
      },
      handleAdStart: (spot: SpotInfo) => {
        if (isTagCommanderReady && spot?.value) {
          setEvent({ ...spot.value, content_video_action: TMS_PLAY, ad_end: false })
          const parsedEvent = parseEvent(event.current)
          hit(
            {
              id: TMS_SPOT_PLAY,
              ...parsedEvent,
            },
            {
              delay: parsedEvent.content_video_playAlong !== 'false' ? 800 : null,
            },
          )
        }
      },
      handleAdPause: () => {
        if (isTagCommanderReady) {
          setEvent({ content_video_action: TMS_PAUSE, ad_end: false })
          hit({
            id: TMS_SPOT_PAUSE,
            ...parseEvent(event.current),
          })
        }
      },
      handleAdResume: () => {
        if (isTagCommanderReady) {
          setEvent({ content_video_action: TMS_PLAY, ad_end: false })
          hit({
            id: TMS_SPOT_RESUME,
            ...parseEvent(event.current),
          })
        }
      },
      handleAdEnd: (spot: SpotInfo) => {
        if (isTagCommanderReady && spot?.value) {
          setEvent({ ...spot.value, content_video_action: TMS_ACTION_END })
          const parsedEvent = parseEvent(event.current)
          hit({
            id: TMS_SPOT_END,
            ...parsedEvent,
          })
        }
      },
      handleMediaInfoUpdate: ({ value: { mediainfo } }) => {
        if (mediainfo?.media?.type === 'live' || mediainfo?.media?.type === 'stream') {
          event.current.content_video_videoName = slugify(mediainfo?.content?.title, {
            lower: true,
          })
          event.current.program_broadcastingChannel = slugify(mediainfo?.media?.channel, {
            lower: true,
          })
          event.current.content_video_videoType =
            mediainfo?.media?.type === 'live' ? 'live' : 'replay'
          event.current.content_video_videoEmNumber = slugify(mediainfo?.content?.emId, {
            lower: true,
          })
          event.current.content_video_duration = String(
            mediainfo?.media?.duration ??
              (mediainfo?.media?.endDate &&
                mediainfo?.media?.startDate &&
                String(
                  (new Date(mediainfo?.media?.endDate).getTime() -
                    new Date(mediainfo?.media?.startDate).getTime()) /
                    1000,
                )) ??
              '0',
          )
          event.current.program_programName = slugify(
            mediainfo?.mediametrie?.chapters[0]?.estatS2,
            { lower: true },
          )
          event.current.program_linkingChannel = slugify(mediainfo?.media?.attach, { lower: true })

          mediaInfoRef.current = {
            content_video_videoName: event.current.content_video_videoName,
            program_broadcastingChannel: event.current.program_broadcastingChannel,
            content_video_videoType: event.current.content_video_videoType,
            content_video_duration: event.current.content_video_duration,
            program_programName: event.current.program_programName,
            program_linkingChannel: event.current.program_linkingChannel,
          }
        } else {
          if (mediainfo?.media?.title) {
            event.current.content_video_videoName = slugify(mediainfo.media.title, { lower: true })
          }
        }
      },
    }),
    [isTagCommanderReady, hit],
  )

  const setupListeners = useCallback(() => {
    Object.keys(AdsPlayerListeners)?.forEach((listener) => {
      if (listeners[AdsPlayerListeners[listener]]) {
        player.current?.on?.(listener, listeners[AdsPlayerListeners[listener]])
      }
    })
  }, [listeners])

  const destroyListeners = useCallback(() => {
    Object.keys(AdsPlayerListeners)?.forEach((listener) => {
      if (listeners[AdsPlayerListeners[listener]]) {
        player.current?.off?.(listener, listeners[AdsPlayerListeners[listener]])
      }
    })
  }, [listeners])

  useEffect(() => {
    if (isPlayerInitialized) setupListeners()
    return () => {
      if (isPlayerInitialized) destroyListeners()
    }
  }, [isPlayerInitialized, setupListeners, destroyListeners])

  useEffect(() => {
    if (videosData[videoId]) {
      const {
        content_video_duration,
        content_video_videoEmNumber,
        content_video_videoFormat,
        content_video_videoRefvideoId,
        content_video_videoType,
        program_attributedEditor,
        program_broadcastingChannel,
        program_programNameMove,
        program_programIdMove,
      } = videosData[videoId]

      event.current = {
        ...event.current,
        content_video_duration: String(content_video_duration),
        content_video_playAlong: String(playAlongTms),
        content_video_videoEmNumber: String(content_video_videoEmNumber),
        content_video_videoFormat: String(content_video_videoFormat),
        content_video_videoRefvideoId: String(content_video_videoRefvideoId),
        content_video_videoType: String(content_video_videoType),
        program_attributedEditor: String(program_attributedEditor),
        program_broadcastingChannel: String(program_broadcastingChannel),
        program_programNameMove,
        program_programIdMove,
      }
    }
  }, [videosData, videoId])
}
