import type { Player as ITF1Player, Events as PlayerEvents } from '@etf1-interne/player'
import { useEffect, useMemo } from 'react'
import { CLICK } from '../constants/tms'
import { CTA_PLAYER_MAPPING } from '../constants/playerEvents'

export function usePlayerButtonEvents(
  player: ITF1Player,
  callback: (
    name: PlayerEvents,
    evt: {
      value: string
    },
    isClickEvent?: boolean,
  ) => void,
  isActive: boolean,
) {
  const playerEvents = Object.keys(CTA_PLAYER_MAPPING)

  // Create callback for each player event
  // This is a memoized function to avoid creating a new function on each render
  // { [event: string]: () => logEvent(name: string, evt: { value: string })
  const eventsCallback = useMemo(
    () =>
      playerEvents.reduce((acc, event) => {
        return {
          ...acc,
          [event]: () => callback(CLICK, { value: CTA_PLAYER_MAPPING[event] }, true),
        }
      }, {} as Record<PlayerEvents, () => void>),
    [playerEvents, callback],
  )

  useEffect(() => {
    if (!isActive) return

    const currentPlayer = player.current

    // Add event listener for each player event
    playerEvents.forEach((event) => {
      currentPlayer.on(event, eventsCallback[event])
    })

    return () => {
      playerEvents.forEach((event) => {
        currentPlayer.off(event, eventsCallback[event])
      })
    }
  }, [eventsCallback, isActive])
}
